import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { useEffect } from 'react'
import firebase from 'firebase/app'
import { FirebaseDatabaseProvider } from '@react-firebase/database'
import ReactGA from 'react-ga4'
import Content from './Content'
import Player from './Player'
import { visitorsRef } from './firebase'

// ReactGA.initialize('G-JVFHX0CDTY', {
//   gaOptions: {
//     storage: 'none',
//     analytics_storage: 'denied',
//     ad_storage: 'denied',
//   },
//   testMode: process.env.NODE_ENV === 'development',
// })
ReactGA.event({
  category: 'BB',
  action: 'Visit',
  label: 'home',
})

const App = () => {

  // useEffect(() => {
  //   fetch('https://api.ipdata.co/?api-key=4db649958dc2ca6ef3fd2d4479da763221cd1e84bcc507790b614ddb')
  //     .then((response) => response.json())
  //     .then((ipApi) => {
  //       if (!ipApi) return
  //       const date = new Date()
  //       visitorsRef
  //         .child(date.toISOString().split('T')[0])
  //         .child(ipApi.country_name ?? 'null')
  //         .child(ipApi.region ?? 'null')
  //         .child(ipApi.city ?? 'null')
  //         .child(ipApi.ip ?
  //           (ipApi.ip as string).replaceAll('.',':') : 'null')
  //         .child(date.toLocaleTimeString([], {
  //           hour: '2-digit',
  //           minute: '2-digit',
  //           second: 'numeric',
  //           hour12: false,
  //         }) + ':' + date.getMilliseconds())
  //         .set({
  //           ipApi: ipApi,

  //           timeOpened: date.valueOf(),
  //           timezone: date.getTimezoneOffset()/60,

  //           pageon: window.location.pathname,
  //           referrer: document.referrer,
  //           previousSites: JSON.stringify(window.history),

  //           browserName: navigator.appName,
  //           browserEngine: navigator.product,
  //           browserVersion1a: navigator.appVersion,
  //           browserVersion1b: navigator.userAgent,
  //           browserLanguage: navigator.language,
  //           browserOnline: navigator.onLine,
  //           browserPlatform: navigator.platform,
  //           javaEnabled: navigator.javaEnabled(),
  //           dataCookiesEnabled: navigator.cookieEnabled,
  //           dataCookies1: document.cookie,
  //           dataStorage: JSON.stringify(localStorage),

  //           sizeScreenW: window.screen.width,
  //           sizeScreenH: window.screen.height,
  //           sizeDocW: window.screen.width,
  //           sizeDocH: window.screen.height,
  //           sizeInW: window.innerWidth,
  //           sizeInH: window.innerHeight,
  //           sizeAvailW: window.screen.availWidth,
  //           sizeAvailH: window.screen.availHeight,
  //           scrColorDepth: window.screen.colorDepth,
  //           scrPixelDepth: window.screen.pixelDepth,
  //         })
  //         .catch((error) => console.error(error))
  //   })
  // })

  return (
    <FirebaseDatabaseProvider firebase={firebase}>
      {/* <Player /> */}
      <div className='font-bold text-4xl text-center mt-3 sm:mt-6'>
        OVER AND OVER club mix
      </div>

      <div className='sm:text-2xl text-xl text-center italic'>
        new track out now, for your consideration
      </div>

      <div className='flex justify-center'>
        <div className='mt-3 mb-10 sm:w-50 w-100 max-w-lg shadow-lg shadow-slate-500'>
          <iframe
            width='100%'
            height='576'
            title='player'
            scrolling='no'
            frameBorder='no'
            allow='autoplay'
            src='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1318593865&color=%23ff5500&auto_play=true&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=false&visual=true'
          ></iframe>
        </div>
      </div>
      <Content />
    </FirebaseDatabaseProvider>
  )
}

export default App
