import ReactGA from 'react-ga4'
import { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import applemusicImg from './assets/applemusic.svg'
import soundcloudImg from './assets/soundcloud.svg'
import spotifyImg from './assets/spotify.svg'
import bandcampImg from './assets/bandcamp.svg'
import instagramImg from './assets/instagram.svg'
import facebookImg from './assets/facebook.svg'
import gmailImg from './assets/gmail.svg'
import ithadtohappenImg from './assets/It-had-to-happen_web.jpg'

const Content = () => {
  const [readMore, setReadMore] = useState(false);

  return (<>
    <div>
      <Container
        className={readMore ? ' read-more' : ''}
      >
        <p>
          It is not very clear who the so-called Bulk Beings are,
          beings that inhabit out of our limited four-dimensional space,
          but with the ability to interact with it.
          {!readMore && (
            <>..&nbsp;
              <Button
                onClick={() => {
                  ReactGA.event({
                    category: 'BB',
                    action: 'Click',
                    label: 'read_more',
                  })
                  setReadMore(!readMore)
                }}
                variant="link"
              >
                read more
              </Button>
            </>
          )}
        </p>

        <p>
          Some defend that they are humans of the future who have evolved
          to the point of existing beyond the four dimensions of the universe we know.
          In this way, they can move through time back and forth,
          the same as we move through the other three dimensions.
        </p>

        <p>
          Following this very same model, the music of Bulk Beings is projected
          to both past and future at the same time by its formal, technical design.
          But it also does it through feelings, combining pain and thrill,
          devotion and resentment, all at the same time, through a multiplicity of layers,
          as if they were additional physical dimensions.
        </p>

        <p>
          Because, although anyone can experience all these emotions, only some will understand
          that all the complexity of the universe we have managed to unravel
          - both the one we know and the one we are barely able to sense - has led us
          to understand that the same happens with our own human nature,
          that we only know a tiny part of who we are and what we could become.
        </p>

        {readMore && (
          <Button
            onClick={() => setReadMore(false)}
            aria-controls="example-collapse-text"
            aria-expanded={readMore}
            variant="link"
          >
            read less
          </Button>
        )}

      </Container>

      <div
        style={{
          backgroundImage: `url(${ithadtohappenImg})`
        }}
        className='sm:w-50 w-100 cover mx-auto'
      >&nbsp;</div>

      <Container>
        <p>
          <strong>It had to happen</strong> is the name of the first album, and it is available
          for you to listen and dance to it.. enjoy the trip!
        </p>
      </Container>

      <div className="platforms">
        <div className="links">
          <a
            onClick={() => ReactGA.event({
              category: 'BB',
              action: 'Click',
              label: 'soundcloud',
            })}
            style={{
              backgroundImage: `url(${soundcloudImg})`,
            }}
            className="soundcloud-link"
            href="https://soundcloud.com/bulkbeingsband"
            target="_blank"
            rel="noreferrer"
          >
            SoundCloud
          </a>

          <a
            onClick={() => ReactGA.event({
              category: 'BB',
              action: 'Click',
              label: 'bandcamp',
            })}
            style={{
              backgroundImage: `url(${bandcampImg})`,
            }}
            className="bandcamp-link"
            href="https://bulkbeings.bandcamp.com"
            target="_blank"
            rel="noreferrer"
          >
            Bandcamp
          </a>

          <a
            onClick={() => ReactGA.event({
              category: 'BB',
              action: 'Click',
              label: 'spotify'
            })}
            style={{
              backgroundImage: `url(${spotifyImg})`,
            }}
            className="spotify-link"
            href="https://open.spotify.com/artist/3QeUCbRmi4frj7AY7PgVnG"
            target="_blank"
            rel="noreferrer"
          >
            Spotify
          </a>

          <a
            onClick={() => ReactGA.event({
              category: 'BB',
              action: 'Click',
              label: 'applemusic',
            })}
            style={{
              backgroundImage: `url(${applemusicImg})`,
            }}
            className="applemusic-link"
            href="https://music.apple.com/artist/bulk-beings/1562676688"
            target="_blank"
            rel="noreferrer"
          >
            Apple Music
          </a>

        </div>

      </div>

      <hr />

      <Container className="text-center small">
        <strong>like us follow us contact us..</strong> we are eager for your feedback!
      </Container>

      <div className="social">

        <div className="links">

          <a
            onClick={() => ReactGA.event({
              category: 'BB',
              action: 'Click',
              label: 'instagram',
            })}
            style={{
              backgroundImage: `url(${instagramImg})`,
            }}
            className="instagram-link"
            href="https://instagram.com/bulkbeings"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>

          <a
            onClick={() => ReactGA.event({
              category: 'BB',
              action: 'Click',
              label: 'facebook',
            })}
            style={{
              backgroundImage: `url(${facebookImg})`,
            }}
            className="facebook-link"
            href="https://facebook.com/bulkbeingsband"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>

          <a
            onClick={() => ReactGA.event({
              category: 'BB',
              action: 'Click',
              label: 'email',
            })}
            style={{
              backgroundImage: `url(${gmailImg})`,
            }}
            className="email-link"
            href="mailto:hey@bulkbeings.com"
            target="_blank"
            rel="noreferrer"
          >
            Email
          </a>

        </div>

      </div>

    </div>
  </>)
}

export default Content